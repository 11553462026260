<!---推广-任务-->
<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="margin-top:12px;min-height: calc(100% - 36px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                    <a-layout style="height:100%;background:#fff;">
                        <a-layout-header style="height:auto;background:#fff;padding:0 20px  ;">
                            <a-row>
                                <a-col :span="20">
                                    <a-form layout="horizontal" style="padding:10px  20px 0px;height:auto;">
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="类别">
                                                    <a-radio-group default-value="UserFlag" v-model="UserFlag" @change="QueryData">
                                                        <a-radio-button value="0">全部</a-radio-button>
                                                        <a-radio-button value="1">新任务</a-radio-button>
                                                        <a-radio-button value="2">已激活</a-radio-button>
                                                        <a-radio-button value="3">已结束</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="类型">
                                                    <a-radio-group default-value="TaskType" v-model="TaskType" @change="QueryData">
                                                        <a-radio-button value="0">全部</a-radio-button>
                                                        <a-radio-button value="9">招生店铺</a-radio-button>
                                                        <a-radio-button value="8">招生名片</a-radio-button>
                                                        <a-radio-button value="7">推广文章</a-radio-button>
                                                        <a-radio-button value="10">H5链接</a-radio-button>
                                                        <a-radio-button value="3">发展合伙人</a-radio-button>
                                                        <!--<a-radio-button value="14">商品</a-radio-button>-->
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>

                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:0px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="任务">
                                                    <a-input placeholder="输入任务名称" v-model="Name" :value="Name" style="width:278px;" />
                                                </a-form-item>

                                                <a-form-item label="">
                                                    <a-button type="primary" class="margin-r" @click="QueryData">
                                                        查询
                                                    </a-button>

                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                    </a-form>
                                </a-col>
                                <a-col :span="4" class="textal_r">
                                    <a-button type="primary" @click="() => (modalVisible = true)">发布新任务 </a-button>
                                    <a-modal width="700px" v-model="modalVisible" title="任务类型">
                                        <AddTaskType></AddTaskType>
                                        <div slot="footer"></div>
                                    </a-modal>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table   bordered :columns="columns" rowKey="ID" :data-source="Tasks" :pagination="pagination" @change="pagerChange" style="padding:14px 20px;" class="yj-table-post">
                            <div class="yj_lists_commodity" slot="message" slot-scope="text, record">
                                <div>
                                    <div> <h2 style="margin-right:12px;">￥{{record.TaskBonus}}</h2></div>
                                </div>
                                <div class="yj_lists_commodity_text">
                                    <div>{{record.Name}}</div>

                                    <div>{{record.ProgressStaus}}</div>
                                </div>
                            </div>

                            <div class="yj_lists_number" v-if="record.TaskType!=3" slot="read" slot-scope="text, record">
                                <div class="yj_lists_score_bd">{{record.ReadTarget}}</div>
                                <div><small>阅读</small></div>
                                
                            </div>
                            <div class="yj_lists_number" v-if="record.TaskType!=3" slot="newuser" slot-scope="text, record">
                                <div class="yj_lists_score_bd">{{record.NewUserTarget}}</div>
                                <div><small>拉新</small></div>
                                
                            </div>
                            <div class="yj_lists_number" v-if="record.TaskType!=3" slot="wantuser" slot-scope="text, record">
                                <div class="yj_lists_score_bd">{{record.WantUserTarget}}</div>
                                <div><small>咨询</small></div>
                                
                            </div>
                            <div class="yj_lists_number" v-if="record.TaskType!=3" slot="report" slot-scope="text, record">
                                <div class="yj_lists_score_bd">{{record.ReportTarget}}</div>
                                <div><small>成交</small></div>
                                
                            </div>
                            <div class="yj_lists_number" v-if="record.TaskType==3" slot="development" slot-scope="text, record">
                                <div class="yj_lists_score_bd">{{record.TaskTarget}}</div>
                                <div><small>发展</small></div>
                                
                            </div>

                            <div class="yj_lists_task" slot="progress" slot-scope="text, record">
                                <div>
                                    <div>领取：</div>
                                    <div>完成：</div>
                                </div>
                                <div class="yj_lists_task_bd">
                                    <div><a-progress :percent="record.AreadyGetCount" stroke-color="green" status="active" :show-info=false /></div>
                                    <div><a-progress :percent="record.AreadyCompleteCount" status="active" :show-info=false /></div>

                                </div>
                                <div style="margin-left:6px;">
                                    <div>{{record.GetCount}}</div>
                                    <div>{{record.CompleteCount}}</div>
                                </div>
                            </div>
                            <div :class="{'yj-lists_task_mark yj-lists_task_mark_1':record.ConductStatus=='新任务','yj-lists_task_mark yj-lists_task_mark-invalid':record.ConductStatus=='已结束','yj-lists_task_mark':record.ConductStatus=='进行中'}" slot="tatus" slot-scope="text, record">{{record.ConductStatus}}</div>
                            <div class="yj_lists_commodity_text " slot="action" slot-scope="text, record">
                                <div> <a-button type="link" style="padding:0;" size="small" @click="openUserTask(record)"><small>数据统计</small></a-button></div>
                                <div v-if="record.IsEdit ==''"> <a-button type="link" style="padding:0;" size="small" @click="SelectRow(record)"><small>任务详情</small></a-button></div>
                                <div v-if="record.IsEdit !=''"> <a-button type="link" style="padding:0;" size="small" @click="EditRow(record)"><small>{{record.IsEdit}}</small></a-button></div>
                                <div v-if="record.ButtonName !=''"> <a-button type="link" style="padding:0;" size="small" @click="UpdateFlag(record)"><small>{{record.ButtonName}}</small></a-button></div>
                            </div>
                        </a-table>
                    </a-layout>

                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import AddTaskType from './AddTaskType';
    export default {
        name: "TaskList",
        data() {
            return {
                Tasks: [],
                TaskList: [],
                columns: [
                    {
                        title: "任务信息",
                        scopedSlots: { customRender: 'message' },
                        width: 200
                    },
                    {
                        title: "",
                        scopedSlots: { customRender: 'read' },
                        width: 50,

                    },
                    {
                        title: "",
                        scopedSlots: { customRender: 'newuser' },
                        width: 50,
                    },
                    {
                        title: "",
                        scopedSlots: { customRender: 'wantuser' },
                        width: 50,
                    },
                    {
                        title: "",
                        scopedSlots: { customRender: 'report' },
                        width: 50,
                    },
                    {
                        title: "",
                        scopedSlots: { customRender: 'development' },
                        width: 50,
                    },
                    {
                        title: "任务进度",
                        scopedSlots: { customRender: 'progress' },
                        width: 100,
                    },
                    {
                        title: "操作",
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                        width: 100
                    },
                    {
                        title: "",
                        scopedSlots: { customRender: 'tatus' },
                        width: 100,
                    }
                ],
                modalVisible: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                UserFlag: "0",
                TaskType: "0",
                Name: "",
            };
        },
        methods: {
            //查询事件
            QueryData() {
                this.loadData();
            },
            //页面跳转事件
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            openUserTask(item) {
                this.$router.push({
                    name: "Task_UserTask",
                    query: { id: item.ID }
                });
            },
            EditRow(e) {
                var id = 0;
                var taskType = 0;
                if (e != '0') {
                    id = e.ID;
                    taskType = e.TaskType;
                }
                if (taskType > 6) {
                    this.$router.push({
                        name: "Task_NewTaskTarget",
                        query: { id: id, taskType: taskType }
                    });
                }
                if (taskType == 3) {
                    this.$router.push({
                        name: "Task_NewTaskBase",
                        query: { id: id, taskType: taskType }
                    });
                }
            },
            SelectRow(e) {
                var id = 0;
                var taskType = 0;
                if (e != '0') {
                    id = e.ID;
                    taskType = e.TaskType;
                }
                if (taskType > 6) {
                    this.$router.push({
                        name: "Task_NewTaskTarget",
                        query: { id: id, taskType: taskType, rowType: 'select' }
                    });
                }
                if (taskType == 3) {
                    this.$router.push({
                        name: "Task_NewTaskBase",
                        query: { id: id, taskType: taskType, rowType: 'select' }
                    });
                }
            },
            UpdateFlag(e) {
                var self = this;
                var con = e.UserFlag == 1 ? '激活' : '结束';
                var flag = e.UserFlag;
                this.$confirm({
                    title: '提示',
                    content: '是否' + con + e.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Task/UpdateFlag",
                            data: {
                                id: e.ID,
                                flag: flag
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                                self.$message.success(con + e.Name + "成功");
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Task/TaskList",
                    data: {
                        ID: self.ID,
                        CustomerID: self.CustomerID,
                        Name: self.Name,
                        TaskBonus: self.TaskBonus,
                        ProgressStaus: self.ProgressStaus,
                        ConductStatus: self.ConductStatus,
                        TaskType: self.TaskType,
                        UserFlag: self.UserFlag,
                        TaskTarget: self.TaskTarget,
                        ReadTarget: self.ReadTarget,
                        NewUserTarget: self.NewUserTarget,
                        WantUserTarget: self.WantUserTarget,
                        ReportTarget: self.ReportTarget,
                        AreadyGetCount: self.AreadyGetCount,
                        GetCount: self.GetCount,
                        CompleteCount: self.CompleteCount,
                        AreadyCompleteCount: self.AreadyCompleteCount,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                        ButtonName: self.ButtonName,
                        IsEdit: self.IsEdit,
                        PageIndex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        self.Tasks = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                        res.data.Data.forEach(element => {
                            element.TaskBonus = parseFloat(element.TaskBonus).toFixed(2);
                        });
                    }
                };
                http.Post(op);
            }
        },
        components: {
            AddTaskType
        },
        mounted() {
            this.loadData();
        }
    }
</script>
<style type="text/css">
    .yj-lists_task_mark_1 {
        background-color: #0094ff;
    }

    .yj-lists_task_mark {
        position: absolute;
        right: -20px;
        top: 11px;
        padding: 2px 22px;
        transform: rotate(48deg);
        background-color: #85BC22;
        font-size: 0.76rem;
        color: #fff;
    }

    .yj-table-post table tr:first-child {
        height: 54px;
    }

    .yj-table-post table tr {
        min-height: 110px;
        height: 110px
    }

        .yj-table-post table tr td:last-child {
            position: relative;
            overflow: hidden;
        }
</style>



