 <template>
 <div>
     <p style="text-align:center">
         <a-button type="primary" class="margin-r" @click="TaskTargetOne" ghost>招生店铺</a-button>
         <a-button type="primary" class="margin-r" @click="TaskTargetTwo" ghost>招生名片</a-button>
         <a-button type="primary" class="margin-r" @click="TaskTargetThree" ghost>推广文章</a-button>
         <a-button type="primary" class="margin-r" @click="TaskTargetFour" ghost>H5链接</a-button>
         <a-button type="primary" class="margin-r" @click="TaskBase" ghost>发展合伙人</a-button>
     </p>
 
  </div>
 </template>
<script>
    export default {
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    }
                }
            }
        },
        methods: {
            TaskTargetOne() {
                this.$router.push({ path: '/TaskManageModule/Task/NewTaskTarget?taskType=9' })
            },
            TaskTargetTwo() {
                this.$router.push({ path: '/TaskManageModule/Task/NewTaskTarget?taskType=8' })
            },
            TaskTargetThree() {
                this.$router.push({ path: '/TaskManageModule/Task/NewTaskTarget?taskType=7' })
            },
            TaskTargetFour() {
                this.$router.push({ path: '/TaskManageModule/Task/NewTaskTarget?taskType=10' })
            },
            TaskBase() {
                this.$router.push({ path: '/TaskManageModule/Task/NewTaskBase?taskType=3' })
            },
        },
        mounted() {
        }
    }
</script>